import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
    Image,
    VStack,
    Text,
    Grid,
    GridItem
} from '@chakra-ui/react'
import axios from 'axios'

export default function ArtPiece() {
    const [artPiece, setArtPiece] = useState({})
    const [artLikes, setArtLikes] = useState([0])
    const dbID = useState("")
    const { id } = useParams()
    
    useEffect(() => {
        axios.get(`https://api.artic.edu/api/v1/artworks/${id}`)
        .then(res => setArtPiece(res.data.data))
        .catch(err => console.log(err))
    }, [])
    
    useEffect(() => {
        axios.get(`/collections`)
            .then(res => {
                var collectionExistsInDB = false
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].id === parseInt(id)) {
                        collectionExistsInDB = true
                        dbID[0] = res.data[i]._id
                        break
                    }
                }
                
                if (collectionExistsInDB) {
                    axios.get(`/collections/${dbID[0]}`)
                        .then(res => {
                            setArtLikes([res.data.likes])
                        })
                        .catch(err => console.log(err))
                }
                else {
                    axios.post(`/collections`, {id: parseInt(id), likes: parseInt(artLikes[0])})
                }
                
            })
            .catch(err => console.log(err))
    }, [])

    

    const buttonStyles = {
        color: '#ffffff',
        border: '1px solid #ffffff'
    }

    return (
        <>
                <Grid templateColumns="1fr 1fr" margin={10}>
                    <GridItem>
                        <Image src={`https://www.artic.edu/iiif/2/${artPiece.image_id}/full/600,/0/default.jpg`} />
                    </GridItem>
                    <GridItem>
                        <VStack alignItems='center' >
                            <Text 
                            color='white' 
                            fontSize='3xl' 
                            fontFamily='Montserrat'>
                                {artPiece.title} {/* // Title */}
                            </Text>
                            <Text 
                            color='white' 
                            fontSize='xl' 
                            fontFamily='Montserrat'>
                                {artPiece.artist_display} {/* // Artist's name and other info */}
                            </Text>
                            <Text
                                color='white' 
                                fontSize='xl' 
                                fontFamily='Montserrat'
                            >
                                {artPiece.classification_titles}
                            </Text>
                            <Text
                                color='white' 
                                fontSize='xl' 
                                fontFamily='Montserrat'
                            >
                                {artPiece.place_of_origin}
                            </Text>
                        </VStack>
                    </GridItem>
                </Grid>
        </>
    )
}